
  import Vue from 'vue';
  import * as api from '@/api';

  export default Vue.extend({
    data() {
      return {
        tabs: [
          {
            label: '全部消息',
            status: null,
            count: 0,
            category: -1,
          },
          {
            label: '审批未通过',
            status: 30,
            count: 0,
            category: 3,
          },
          {
            label: '系统消息',
            status: 10,
            count: 0,
            category: 0,
          },
        ],
        currentTabIdx: -1,
        list: [] as api.NewsItem[],
        next: true,
        stop: true,
      };
    },
    mounted() {
      this.getCategories();
      this.getSitMessages();
    },
    watch: {
      currentTabIdx() {
        this.list = [];
        this.next = true;
        this.getSitMessages();
      },
    },
    created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '消息中心',
          path: 'message',
        },
      ]);
    },
    methods: {
      async toInfo(item) {
        const opt = {
          pvId: item.extData.pvId,
          productId: item.extData.productId,
          step: 0,
        };
        const res = await api.getProductInfo(opt);
        if (!api.error(res)) {
          this.$store.commit('productInfoModule/currentProductInfo', {
            productId: res.data.productId,
            pvId: res.data.pvId,
            status: 20,
            statusTxt: '未通过',
            name: res.data.info.name,
          });
          this.$router.push({
            path: '/product/edit',
          });
        }
      },
      async details(item, idx) {
        if (item.breviary.length > 50) {
          this.$set(this.list[idx], 'change', !this.list[idx].change); // 前台显示
        }
        if (item.isReaded) return;
        const res = await api.readMessages({ id: item.id });
        if (!api.error(res)) {
            item.isReaded = true;
            this.$store.dispatch('getUnreadCount');
        }
      },
      scrollEvent(e) {
        const rect = (document.getElementById('message_list') as any).getClientRects()[0];
        if ((e.target.offsetHeight + e.target.scrollTop) >= rect.height) {
          !this.stop && this.next && this.getSitMessages();
        }
      },
      async getSitMessages() {
        this.stop = true;
        const opt: api.Messages = {
          receiverType: 1,
          skipCount: this.list.length,
          maxResultCount: 10,
        };
        if (this.currentTabIdx !== -1) opt.category = this.currentTabIdx;
        const res = await api.getSitMessages(opt);
        if (!api.error(res)) {
          res.data.items.forEach((e) => {
            e.breviary = e.content.replace(/<img[^>]+>/g, '*[图片]*').replace(/<[^>]+>/g, '');
            if (e.breviary.length > 50) {
              e.breviary = `${e.breviary.slice(0, 50)}...`;
            }
          });
          this.list = [...this.list, ...res.data.items];
          if (this.currentTabIdx === -1) this.tabs[0].count = res.data.totalCount;
          if (res.data.items.length === 0) {
            this.next = false;
          }
        }
        this.stop = false;
      },
      async getCategories() {
        const res = await api.getCategories({ receiverType: 1 });
        if (!api.error(res)) {
          if (res.data.length > 0) {
            const notPass = res.data.find((e) => e.category === 3);
            this.tabs[1].count = notPass ? notPass.count : 0;
            const system = res.data.find((e) => e.category === 0);
            this.tabs[2].count = system ? system.count : 0;
          }
        }
      },
    },
  });
